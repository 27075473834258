@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* -------------------- Font Imports -------------------- */ /* Kept Syne for specific uses */

/* -------------------- Reset and Base Styles -------------------- */
body {
  background: var(--primary-background);
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

li {
  list-style: none;
}

.App {
  font-family: 'Poppins', sans-serif; /* Replaced Sora */
  background-color: var(--primary-background);
  color: var(--primary-text-color);
  width: 100%;
  max-width: 100vw; /* Cap width to viewport */
  overflow-x: hidden;
  transition: background-color 350ms ease-in-out;
}

h2 {
  font-family: 'Playfair Display', serif; /* Replaced Syne Mono */
}

/* -------------------- Theme Variables -------------------- */
[data-theme="dark"] {
  --primary-background: #F8F3D9;
  --primary-text-color: #3E2723;
  --secondary-text-color: #5D4037;
  --card-background: #A1887F;
  --card-2-background: #8D6E63;
  --card-3-background: #6D4C41;
  --card-text-color: #FFFFFF;
  --accent-text-color: #D84315;
  --hover-text-color: #B71C1C;
  --toggle-bg: #455A64;
  --icon-color: #FFFFFF;
  --record-border: #424242;
  --green: #2E7D32;
}

/* -------------------- Global Layout -------------------- */
#tsparticles {
  z-index: 0;
}

/* -------------------- Navbar -------------------- */
.Navbar {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 2%;
  width: 100%; 
  max-width: 100vw;
  box-sizing: border-box; 
}

.App-header {
  z-index: 1;
  color: var(--primary-text-color);
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: 24px;
  font-weight:ក్షេtrict;
}

.App-header li {
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
  margin-right: 50px;
  justify-content: right;
  list-style: none;
  position: relative; /* For the underline effect */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

ul.App-header {
  display: flex;
  flex-direction: row;
}

/* New hover effect replacing blur */
ul.App-header li:hover {
  transform: scale(1.1); /* Slight enlargement */
  color: var(--accent-text-color); /* Vibrant color shift */
}

/* Underline animation */
.App-header li::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px; /* Adjust based on spacing preference */
  left: 0;
  background-color: var(--accent-text-color);
  transition: width 0.3s ease-in-out;
}

.App-header li:hover::after {
  width: 100%; /* Underline grows from left to right */
}

.App-link {
  color: var(--primary-text-color);
  text-decoration: none;
}

.hamburger {
  display: none;
}

.App-header-vertical {
  display: none; /* Hidden by default */
  flex-direction: column;
  position: fixed;
  top: 80px;
  right: 20px;
  width: 150px;
  background: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.25);
  z-index: 1000;
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.App-header-vertical.open {
  display: flex;
}

.App-link.vertical {
  color: var(--card-text-color);
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
  font-size: 16px;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, background 0.3s ease-in-out;
  position: relative;
}

.App-link.vertical:hover {
  transform: scale(1.05); /* Slightly smaller scale for vertical */
  background: var(--accent-text-color);
  color: var(--card-text-color);
}

.App-link.vertical::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 5px;
  left: 15px;
  background-color: var(--card-text-color);
  transition: width 0.3s ease-in-out;
}

.App-link.vertical:hover::after {
  width: calc(100% - 30px); /* Adjust for padding */
}

.App-link.vertical:focus {
  background: transparent;
  color: var(--card-text-color);
}

.Logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 450px;
  font-family: 'Syne', sans-serif; /* Kept Syne */
}

.logoLeft,
.logoRight {
  margin: 0%;
  margin-right: 1%;
  height: 50px;
  z-index: 2;
  transition: .5s ease-in-out;
}

.logoLeft {
  margin-left: -1%;
  margin-right: 0%;
}

.logoRight {
  margin-left: 0%;
}

.Logo:hover .logoLeft {
  margin-left: 0%;
  margin-right: 1%;
  transition-duration: .5s ease-in-out;
}

.Logo:hover .logoRight {
  margin-left: 9.5%;
  transition: .5s ease-in-out;
}

.logoText {
  justify-content: center;
  z-index: 0;
  width: 0.000001px;
  font-size: 24px;
  font-weight: 700;
  transition-duration: .5s;
  color: rgba(255, 255, 255, 0);
}

.Logo:hover .logoText {
  transition-duration: 1s;
  color: var(--primary-text-color);
}

/* -------------------- Homepage -------------------- */
.homePage {
  width: 100%;
  max-width: 1200px; /* Added for consistent centering */
  margin: 0 auto; /* Centers the entire homepage content */
  padding: 0 2%; /* Matches the 2% margin spacing */
  box-sizing: border-box;
}

.heroContainer,
.musicContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 5% 0; /* Updated: Removed 2% side margins */
  width: 100%;
  max-width: 100%; /* Stay within homePage's max-width */
}

.heroSection {
  display: flex;
  align-items: center;
}

.heroText {
  margin: 0 15px; /* Updated: Reduced from 30px for symmetry */
  z-index: 1;
  text-align: left;
  flex: 1 1; /* Added: Allow it to grow/shrink */
  max-width: 600px; /* Added: Cap width */
}

.heroText > h1 {
  line-height: 80px;
  margin: 5px 0;
  color: var(--secondary-text-color);
  font-family: 'Playfair Display', serif; /* Replaced Syne */
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0px;
}

.heroText > h3,
.heroText > h4 {
  max-width: 600px;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  margin: 20px 0;
  font-family: 'Poppins', sans-serif; /* Replaced Sora */
}

.heroText > h4 > strong {
  color: var(--accent-text-color);
  font-weight: 800;
  font-style: italic;
  transition: ease-in-out .3s;
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
}

.heroText > h4 > strong:hover {
  color: var(--hover-text-color);
  transition: ease-in-out .3s;
}

.heroText > h3 {
  margin-top: 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
}

.homeButtons {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 700;
  color: var(--secondary-text-color);
  font-family: 'Poppins', sans-serif; /* Replaced Syne Mono */
  text-decoration: none;
  margin-right: 30px;
  border: 2px solid var(--secondary-text-color);
  padding: 5px 10px;
  border-radius: 10px;
  transition: 250ms ease-in-out;
}

.homeButtons:hover {
  color: white;
  background-color: var(--green);
  transition: 250ms ease-in-out;
}

.homeButtons > p {
  margin: 0 10px;
}

.homeButtons:hover > p {
  animation: slide1 1s ease-in-out infinite;
}

.recordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  background: var(--secondary-text-color);
  border: 8px solid var(--record-border);
  border-radius: 15%;
  margin-right: 30px;
  z-index: 1;
}

.rotatingCircularImage {
  border-radius: 50%;
}

.Circle,
.recordCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 175px;
  background: linear-gradient(45deg, var(--black), var(--grey));
  border: solid 7px pink;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.Circle {
  background: var(--primary-background);
  border: solid 2px rgb(122, 88, 94);
  width: 50px;
  height: 50px;
}

.latestInfo {
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
  margin: 0 15px; /* Updated: Standardized margin */
  text-align: right; /* Updated: Changed from left to right */
  width: 370px; /* Kept original width */
  flex-shrink: 0; /* Added: Prevent shrinking */
}

.latestInfo > h2 {
  margin-bottom: 5px;
  color: var(--accent-text-color);
  font-weight: 600;
}

.latestInfo > h3 {
  font-family: 'Poppins', sans-serif; /* Replaced Sora */
  font-weight: 400;
  margin-bottom: 10%;
}

.spotifyContainer,
.latestInfo {
  padding: 0;
  width: 370px;
  flex-direction: column;
  z-index: 1;
}

.spotifyContainer {
  width: 500px;
  margin: 0 15px; /* Updated: Standardized margin, removed right-only */
  flex-shrink: 0; /* Added: Prevent shrinking */
}

.latestText > h3 {
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
  margin: 40px;
  color: #FFFCF5;
}

.connect {
  margin-bottom: 2%;
}

.socialsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.socialsContainer > a,
.socialsContainer > a > img {
  width: 50px;
  height: 50px;
  margin: 5px;
  transition: .5s ease-in-out;
  opacity: 100%;
}

.socialsContainer > a:hover {
  transition: .5s ease-in-out;
  opacity: 50%;
}

/* -------------------- About Page -------------------- */
.cubeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  perspective: 1000px;
  margin: 60px 20px;
  z-index: 1;
  flex-shrink: 0;
}

.cube {
  width: 150px;
  height: 150px;
  position: relative;
  transform-style: preserve-3d;
  animation: spin 10s infinite linear; /* Default rotation */
}

.cubeContainer:hover .cube {
  animation-play-state: paused; /* Pause rotation on hover */
}

.face {
  position: absolute;
  width: 150px;
  height: 150px;
  background: linear-gradient(45deg, rgba(161, 136, 127, 1), rgba(109, 76, 65, 1)); /* Increased opacity for stronger gradient */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.7), 0 6px 12px rgba(0, 0, 0, 0.3); /* Stronger inner shadow + outer shadow */
  border: 1px solid var(--card-2-background);
  overflow: hidden;
}

.face img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.front  { transform: translateZ(75px); filter: brightness(1.3); } /* Increased brightness */
.back   { transform: translateZ(-75px) rotateY(180deg); filter: brightness(0.7); } /* Darker back */
.right  { transform: translateX(75px) rotateY(90deg); }
.left   { transform: translateX(-75px) rotateY(-90deg); }
.top    { transform: translateY(-75px) rotateX(90deg); }
.bottom { transform: translateY(75px) rotateX(-90deg); }

@keyframes spin {
  0% { transform: rotateX(0deg) rotateY(0deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
}

.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0% 12%;
  z-index: 1;
}

.aboutContainer {
  flex-direction: row-reverse;
  z-index: 1;
}

.aboutText {
  max-width: 550px;
  margin-right: 30px;
}

.aboutText > h2 {
  font-size: 60px;
  margin-bottom: 0;
  color: var(--accent-text-color);
}

.aboutText > p {
  font-size: 20px;
}

.aboutText > p strong {
  color: var(--accent-text-color);
}

.scrollParent {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

.imageCarouselContainer {
  display: flex;
  overflow: hidden;
  height: 200px;
  width: 900px;
  margin: 10px 0%;
}

.scrollElement {
  width: inherit;
  height: inherit;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0;
}

.scrollElement > img {
  height: 200px;
  width: 150px;
  margin: 0 5px;
  border-radius: 20px;
}

/* -------------------- Projects Page -------------------- */
.projectContainer {
  margin-top: 0%;
}

.projectPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

.projectPage > h2 {
  text-align: left;
  width: 980px;
  font-size: 40px;
  margin: 50px 0 0 0;
  z-index: 1;
}

.projectPage > p {
  text-align: left;
  width: 980px;
  justify-content: flex-start;
  z-index: 1;
}

.cardList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
}

.projectCard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 200px;
  height: 330px;
  padding: 0 20px;
  border-radius: 20px;
  margin: 4%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transform-style: preserve-3d;
  transition: 250ms ease-in-out;
  cursor: pointer;
  perspective: 1000px;
}

.cardFront:hover {
  transition: 250ms ease-in-out;
  transform: translateY(-20px);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.projectCard.flip {
  transform: rotateY(180deg);
}

.projectCard .cardFront,
.projectCard .cardBack {
  transition: 250ms ease-in-out;
  height: 100%;
  width: 100%;
  padding: 10%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  align-items: flex-start;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border: 3px solid var(--sage-green);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.cardFront {
  background: radial-gradient(circle, var(--card-background) 0%, var(--card-2-background) 46%, var(--card-3-background) 95%);
}

.cardBack {
  background: radial-gradient(circle, var(--green) 0%, var(--light-green) 46%, var(--sage-green) 95%);
  transform: rotateY(180deg);
}

.cardImageContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.cardImage {
  height: 200px;
  width: 200px;
  border: 2px solid var(--primary-background);
  border-radius: 10px;
  background: var(--beige);
}

.cardHeader {
  color: var(--card-text-color);
}

.cardHeader > h3 {
  font-family: 'Space Mono', monospace; /* Replaced Syne */
  font-weight: 800;
  font-size: 20px;
  margin: 15px 0 0 0;
}

.cardBack > .cardHeader > h2 {
  font-family: 'Playfair Display', serif; /* Replaced Syne */
  font-size: 22px;
}

.cardBack > .cardHeader > h3 {
  font-weight: 500;
  font-size: 15px;
}

.cardBack > .cardHeader > h4 {
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
}

.cardInfo > h4 {
  font-weight: 500;
  color: var(--card-text-color);
  font-size: 16px;
  margin: 5px 0 0 0;
}

.cardDetails > h5,
.cardDetails > h5 > a {
  font-weight: 400;
  font-size: 13px;
  color: var(--card-text-color);
  margin: 5px 0 0 0;
}

.cardDetails > h5 > a {
  font-weight: 600;
  text-decoration: underline;
  transition: 250ms ease-in;
}

.cardDetails > h5 > a:hover {
  color: var(--accent-text-color);
  transition: 250ms ease-in;
}

.playButton {
  width: 30px;
  height: 30px;
  margin: 10px 10px 10px 0;
  transition: 250ms ease-in-out;
}

.playButton:hover {
  transition: 250ms ease-in-out;
  transform: translateY(-3px);
}

.cardLink {
  color: var(--card-text-color);
  font-size: 14px;
  text-decoration: underline;
}

/* Internship Carousel */
.internship-carousel {
  width: 100%;
  max-width: 100vw;
  padding: 2rem 0;
  overflow: visible; /* Changed from hidden to visible to prevent clipping */
}

.internship-actions {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.inline-next-button {
  background: transparent;
  color: #00C4B4;
  border: 2px solid #00C4B4;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-family: 'Space Mono', monospace;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.inline-next-button:hover {
  background: #00C4B4;
  color: #1E2A33;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 50px; /* Add padding to accommodate arrows */
  box-sizing: border-box;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.internship-card {
  position: relative;
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  background: linear-gradient(145deg, #2A3B47, #1E2A33);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.internship-image-container {
  width: 40%;
  height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.internship-image {
  width: 100%;
  height: 100%; /* Use full container height */
  object-fit: cover; /* Ensure image scales without distortion */
  transition: transform 0.5s ease-in-out;
}

.image-caption {
  width: 100%;
  background: rgba(30, 42, 51, 0.9);
  padding: 0.5rem 0;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Space Mono', monospace;
  font-size: 0.9rem;
  font-weight: 400;
}

.image-caption p {
  margin: 0;
}

.internship-details {
  width: 60%;
  padding: 2rem;
  color: #FFFFFF;
  background: rgba(30, 42, 51, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.internship-title {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  color: #FFFFFF;
  text-transform: uppercase;
}

.internship-subtitle {
  font-family: 'Space Mono', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0 1rem 0;
  color: #E0E0E0;
  opacity: 0.9;
}

.internship-description {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  color: #B0B0B0;
  opacity: 0.8;
}

.internship-tech {
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
  color: #D0D0D0;
}

.internship-tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.tag {
  background: #3E5A6B;
  color: #FFFFFF;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-family: 'Space Mono', monospace;
  font-size: 0.85rem;
  text-transform: uppercase;
  transition: background 0.3s ease-in-out;
}

.tag:hover {
  background: #D84315;
}

.internship-link {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: transparent;
  color: #00C4B4;
  text-decoration: none;
  border: 2px solid #00C4B4;
  border-radius: 8px;
  font-family: 'Space Mono', monospace;
  font-size: 0.95rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.internship-link:hover {
  background: #00C4B4;
  color: #1E2A33;
  border-color: #00C4B4;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.carousel-button:hover {
  background: #D84315;
  border-color: #D84315;
}

.prev {
  left: -50px; /* Moved further out */
}

.next {
  right: -50px; /* Moved further out */
}

.carousel-dots {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
  z-index: 10;
}

.carousel-container {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.internship-card {
  width: 100%;
  max-width: 800px;
}

.dot {
  width: 10px;
  height: 10px;
  background: rgba(194, 29, 29, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background: rgb(16, 45, 187);
}

.dot:hover {
  background: #B71C1C;
}

/* Responsive Design for Internship Carousel */
@media screen and (max-width: 900px) {
  .carousel-container {
    max-width: 700px;
    padding: 0 40px; /* Adjusted padding for arrows */
  }

  .internship-card {
    min-height: 280px;
    max-width: 650px;
  }

  .internship-image-container {
    height: 280px;
  }

  .prev {
    left: -40px;
  }

  .next {
    right: -40px;
  }
}

@media screen and (max-width: 700px) {
  .internship-carousel {
    padding: 1.5rem 0;
  }

  .carousel-container {
    max-width: 90%;
    padding: 0 35px; /* Space for arrows */
  }

  .internship-card {
    flex-direction: column;
    min-height: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  .internship-image-container {
    width: 100%;
    height: 200px; /* Fixed height for consistency */
  }

  .internship-image {
    height: 100%; /* Use full container height */
  }

  .internship-details {
    width: 100%;
    padding: 1.5rem;
  }

  .carousel-button {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  .prev {
    left: -35px; /* Adjusted to stay outside card */
  }

  .next {
    right: -35px; /* Adjusted to stay outside card */
  }

  .carousel-dots {
    margin-top: 15px;
  }
}

@media screen and (max-width: 500px) {
  .internship-carousel {
    padding: 1rem 0;
  }

  .carousel-container {
    max-width: 95%;
    padding: 0 30px;
  }

  .internship-card {
    border-radius: 10px;
  }

  .internship-image-container {
    height: 180px;
  }

  .internship-image {
    height: 100%;
  }

  .internship-details {
    padding: 1rem;
  }

  .internship-title {
    font-size: 1.5rem;
  }

  .internship-subtitle {
    font-size: 1rem;
  }

  .internship-description,
  .internship-tech {
    font-size: 0.85rem;
  }

  .internship-link {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .tag {
    font-size: 0.75rem;
    padding: 0.2rem 0.6rem;
  }

  .image-caption {
    font-size: 0.8rem;
    padding: 0.4rem 0;
  }

  .carousel-button {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }

  .prev {
    left: -30px;
  }

  .next {
    right: -30px;
  }
}

@media screen and (max-width: 400px) {
  .carousel-container {
    max-width: 100%;
    padding: 0 25px;
  }

  .internship-card {
    margin: 0;
  }

  .internship-image-container {
    height: 150px;
  }

  .internship-image {
    height: 100%;
  }

  .internship-details {
    padding: 0.8rem;
  }

  .internship-title {
    font-size: 1.3rem;
  }

  .internship-subtitle {
    font-size: 0.9rem;
  }

  .internship-description,
  .internship-tech {
    font-size: 0.8rem;
  }

  .internship-link {
    padding: 0.3rem 0.6rem;
    font-size: 0.85rem;
  }

  .carousel-dots {
    grid-gap: 8px;
    gap: 8px;
    margin-top: 10px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }

  .carousel-button {
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
  }

  .prev {
    left: -25px;
  }

  .next {
    right: -25px;
  }
}

/* Classes Section */
.classes-container {
  background-color: transparent;
  border: 2px solid #cd4747;
  border-radius: 8px;
  color: var(--primary-text-color);
  text-align: center;
  padding: 1rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  overflow-x: auto;
}

.classes-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.semester-row {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.semester-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  min-width: 130px;
  max-width: 200px;
}

.semester-column h3 {
  margin-bottom: 1rem;
  text-decoration: underline;
}

.class-box {
  margin: 0.25rem 0;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
}

.breadth { background-color: #B71C1C; }
.cs { background-color: #2E7D32; }
.ee { background-color: #6A1B9A; }
.phys { background-color: #6eda15; }
.math { background-color: #1565c0; }
.graduate { background-color: #ff6f00; }

.classes-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  thex: 0 1 auto;
  min-width: 100px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 4px;
}

/* Video Grid Section */
.video-grid-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 16px;
}

.video-item {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(43.33% - 16px);
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.video-item video {
  display: block;
  width: 100%;
  height: auto;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-item:hover .video-overlay {
  opacity: 1;
}

/* Resume Page */
.resumePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  padding: 2rem 0;
}

.resumeContainer {
  max-width: 980px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.resumeContainer h2 {
  font-family: 'Playfair Display', serif;
  font-size: 40px;
  color: var(--accent-text-color);
  margin-bottom: 1rem;
  text-align: center;
}

.resumePreview {
  width: 100%;
  max-width: 900px;
  background: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.resumeEmbed {
  display: block;
  width: 100%;
  height: 800px; 
  filter: none !important; 
}

.mobileFallback {
  display: none;
  font-family: 'Poppins', sans-serif; 
  color: var(--primary-text-color);
  text-align: center;
  padding: 1rem;
  font-size: 16px;
}

.downloadButtonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.resumeDownloadButton {
  font-size: 20px;
  font-weight: 700;
  color: var(--secondary-text-color);
  font-family: 'Poppins', sans-serif; 
  text-decoration: none;
  border: 2px solid var(--secondary-text-color);
  padding: 10px 20px;
  border-radius: 10px;
  transition: 250ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}

.resumeDownloadButton:hover {
  color: white;
  background-color: var(--green);
}

.resumeDownloadButton p {
  margin: 0 8px;
  font-size: 20px;
}

.resumeDownloadButton:hover p {
  animation: slideDown 1s ease-in-out infinite;
}

/* -------------------- Contact Page -------------------- */
.contactPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.headshotImageContainer {
  margin-top: 5%;
  width: 300px;
  height: 400px;
}

.Headshot {
  width: 275px;
  height: 400px;
  border-radius: 150px 150px 100px 0px;
  box-shadow: 25px -20px var(--light-green);
}

/* -------------------- Footer -------------------- */
.footer {
  font-family: 'Space Mono', monospace;
  z-index: 10;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.footer-heading {
  font-size: 16px;
  z-index: 1;
  text-align: center;
  margin: 0;
  padding: 0 10px;
  margin-bottom: 10px;
}

.footer-heading p {
  margin: 0;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 50px;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
}

.footer-links a {
  text-decoration: none;
  color: var(--primary-text-color);
  transition: color .3s linear, -webkit-text-decoration-color 1000ms ease-in-out;
  transition: text-decoration-color 1000ms ease-in-out, color .3s linear;
  transition: text-decoration-color 1000ms ease-in-out, color .3s linear, -webkit-text-decoration-color 1000ms ease-in-out;
}

.footer-links a:focus,
.footer-links a:hover {
  -webkit-text-decoration: underline 1px var(--green);
          text-decoration: underline 1px var(--green);
  color: var(--primary-text-color);
  transition: .3s linear;
}

.footer-link {
  display: flex;
  line-height: 0px;
}

.footer-links img {
  height: 30px;
  width: 30px;
  fill: var(--icon-color);
}

/* -------------------- Dark Mode Toggle -------------------- */
.toggleContainer {
  display: flex;
  align-items: left;
}

.toggle {
  visibility: hidden;
}

.toggle + label {
  position: relative;
  display: block;
  cursor: pointer;
  color: var(--toggle-bg);
  box-shadow: 0px 2px 5px rgba(109, 107, 107, 0.2);
}

.toggle + label::before {
  content: "";
  height: 40px;
  width: 80px;
  border-radius: 40px;
  background: linear-gradient(180deg, var(--toggle-bg-2), var(--toggle-bg));
  margin-right: 5px;
  transition: background-color 350ms ease-in-out, transform 250ms ease-in-out;
}

.toggle + label::after {
  content: "";
  height: 32px;
  width: 32px;
  border-radius: 40px;
  background-color: var(--toggle-bg);
  position: absolute;
  left: 5px;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
}

.toggle:checked + label::after {
  left: 75px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, var(--cyan), var(--light-green));
}

label {
  width: 80px;
  height: 40px;
  position: relative;
  display: block;
  background: linear-gradient(180deg, var(--toggle-bg-2), var(--toggle-bg));
  border-radius: 50px;
  cursor: pointer;
}

label:after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: linear-gradient(180deg, var(--accent-text-color), var(--cherry-blossom));
  border-radius: 30px;
}

input {
  width: 0;
  height: 0;
  visibility: hidden;
}

input:checked + label {
  background: linear-gradient(170deg, var(--dark-purple), var(--cyan));
}

input:checked + label:after {
  left: 75px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, var(--cyan), var(--light-green));
}

label,
label:after {
  transition: 0.3s;
}

label:active:after {
  width: 50px;
}

/* -------------------- Scrollbar Customization -------------------- */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background: var(--accent-text-color);
  border-radius: 8px;
  border: 2px solid var(--primary-background);
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--hover-text-color);
}

.classes-container::-webkit-scrollbar,
.video-grid-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.classes-container::-webkit-scrollbar-track,
.video-grid-container::-webkit-scrollbar-track {
  background: var(--card-background);
  border-radius: 4px;
}

.classes-container::-webkit-scrollbar-thumb,
.video-grid-container::-webkit-scrollbar-thumb {
  background: var(--accent-text-color);
  border-radius: 4px;
  border: 1px solid var(--card-background);
}

.classes-container::-webkit-scrollbar-thumb:hover,
.video-grid-container::-webkit-scrollbar-thumb:hover {
  background: var(--hover-text-color);
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-text-color) var(--primary-background);
}

/* -------------------- Loader -------------------- */
.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border-radius: 50%;
  animation: loading 4s infinite linear;
}

/* -------------------- Animations -------------------- */
@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes slide1 {
  0%, 100% { transform: translate(0, 0); }
  50% { transform: translate(5px, 0); }
}

@keyframes slideDown {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(5px); }
}

@keyframes primary {
  from { left: 10%; }
  to { left: -90%; }
}

@keyframes secondary {
  from { left: 110%; }
  to { left: 10%; }
}

@keyframes primary2 {
  from { left: 0%; }
  to { left: -100%; }
}

@keyframes secondary2 {
  from { left: 100%; }
  to { left: 0%; }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* -------------------- Modal Styles -------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(62, 39, 35, 0.85);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--card-background);
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--accent-text-color);
  animation: modalFadeIn 0.3s ease-in-out;
}

.modal-content h2 {
  color: var(--accent-text-color);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.modal-content p {
  color: var(--card-text-color);
  font-family: 'Poppins', sans-serif; /* Replaced Sora */
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.modal-button {
  background: var(--green);
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 8px;
  font-family: 'Space Mono', monospace; /* Replaced Syne Mono */
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.modal-button:hover {
  background: var(--accent-text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba penal(0, 0, 0, 0.2);
}

/* Modal Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* -------------------- Media Queries -------------------- */
@media screen and (max-width: 1150px) {
  .aboutText { width: 400px; }
}

@media screen and (max-width: 1015px) {
  .Logo { margin-left: 30px; }
  .App-header li { margin-left: 30px; margin-right: 30px; }
  .heroText > h1 { font-size: 54px; line-height: 64px; }
  .heroText { margin-top: 5%; }
  .heroText > h3, .heroText > h4 { font-size: 24px; line-height: 30px; max-width: 90%; }
  .homeButtons { font-size: 16px; width: 100px; }
  .homeButtonImg { margin: 0 10px; }
  .App-header { font-size: 18px; white-space: nowrap; }
  .aboutText { font-size: 20px; width: 100%; }
  .aboutText > h2 { font-size: 32px; }
  .projectPage > p, .projectPage > h2 { width: 600px; z-index: 1; }
  .imageCarouselContainer { margin: 10px 2%; width: 100%; }
  .headshotImageContainer, .Headshot { width: 205px; height: 300px; }
  .aboutText > p { font-size: 16px; }
  .resumeContainer h2 { font-size: 32px; }
  .resumeEmbed { height: 600px; }
  .resumeDownloadButton { font-size: 16px; padding: 8px 16px; }
}

@media screen and (max-width: 820px) {
  .Logo:hover .logoRight { margin-left: 40px; }
  .primary, .primary2, .secondary, .secondary2 { animation: none; }
  .aboutContainer { flex-direction: column; }
  .imageCarouselContainer, .scrollParent { overflow: auto; height: 150px; }
  .scrollElement > img { height: 150px; width: 120px; margin: 0 5px; border-radius: 10px; }
  .socialsContainer { padding: 10px 20px; }
  .heroText, .heroText > h3 { margin-bottom: 0; }
  .heroText > h4 { font-size: 20px; line-height: 28px; margin-top: 1%; }
  .socialsContainer > a { margin: 0 5px; }
  .socialsContainer > a > img { width: 35px; height: 35px; }
}

@media screen and (max-width: 700px) {
  .semester-row.swipe-container {
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 1rem;
    scroll-behavior: smooth;
    flex-wrap: nowrap;
  }
  .semester-column {
    flex: 0 0 100%;
    scroll-snap-align: center;
    min-width: 160px;
    max-width: 100%;
  }
  .classes-container { padding: 1rem; width: 100%; }
  .video-grid-container { padding: 12px; }
  .video-item { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 12px); }
  .semester-row.swipe-container::-webkit-scrollbar {
    height: 6px;
  }
  .semester-row.swipe-container::-webkit-scrollbar-thumb {
    background: var(--accent-text-color);
    border-radius: 3px;
  }
  .semester-row.swipe-container::-webkit-scrollbar-track {
    background: var(--card-background);
    border-radius: 3px;
  }
}

@media screen and (max-width: 690px) {
  .hamburger { display: block; margin-right: 20px; }
  .App-link.horizontal { display: none; }
  .Navbar { justify-content: space-between; padding: 10px 15px; } /* Updated for hamburger */
  .App-header { display: none; } /* Updated for hamburger */
  .aboutText { margin: 1% 2%; }
  .aboutText > p { font-size: 14px; }
  .heroContainer, .musicContainer, .recordContainer, .spotifyContainer {
    flex-direction: column;
    margin: 5% auto;
    width: 100%;
    max-width: 400px;
    align-items: center;
  }
  .heroText {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 0 10px;
  }
  .recordContainer {
    margin: 20px auto;
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
  }
  .buttonContainer {
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 20px;
  }
  .homeButtons {
    margin-right: 0;
    margin: 0 5px;
  }
  .latestInfo {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 0 10px;
  }
  .spotifyContainer {
    margin: 20px auto;
    width: 100%;
    max-width: 300px;
  }
  .socialsContainer {
    justify-content: center;
    margin: 0 auto;
    padding: 0;
  }
  .socialsContainer > a { width: 35px; }
  .heroText > h1 { font-size: 64px; line-height: 72px; }
  .spotifyContainer > iframe { width: 100%; }
  .projectCard { margin-bottom: 15%; }
  .Logo:hover .logoLeft { margin-left: 4%; }
  .projectPage > p, .projectPage > h2 { width: 300px; }
  .App-header-vertical { top: 60px; right: 10px; width: 140px; padding: 10px; } /* Updated for hamburger */
  .App-link.vertical { font-size: 14px; padding: 10px 12px; } /* Updated for hamburger */
}

@media screen and (min-width: 650px) {
  .App-header-vertical { display: none; }
}

@media screen and (max-width: 500px) {
  .App-header { font-size: 12px; }
  .heroSection, .heroText, .musicContainer { flex-direction: column; width: 100%; }
  .heroSection, .heroText, .spotifyContainer { margin: 5% auto; }
  .recordContainer { margin: 20px auto; }
  .heroText > h1 { font-size: 48px; line-height: 60px; }
  .heroText > h3, .heroText > h4 { line-height: 28px; font-size: 20px; margin-top: 2%; }
  .headshotImageContainer, .Headshot { width: 160px; height: 100%; }
  .App-header li { margin-left: 15px; }
  .Navbar { padding: 8px 10px; } /* Updated for hamburger */
  .hamburger { margin-right: 10px; } /* Updated for hamburger */
  .App-header-vertical { width: 120px; right: 5px; top: 55px; } /* Updated for hamburger */
  .App-link.vertical { font-size: 13px; padding: 8px 10px; } /* Updated for hamburger */
  .projectPage > p, .projectPage > h2 { width: 300px; }
  .projectPage > h2 { font-size: 32px; }
  .projectPage > p { font-size: 16px; }
  .video-item { flex: 0 1 100%; }
  .video-grid-container { grid-gap: 12px; gap: 12px; }
  .resumeContainer { padding: 0.5rem; }
  .resumeContainer h2 { font-size: 24px; }
  .resumeEmbed { display: none; }
  .mobileFallback { display: block; }
  .resumePreview { background: none; box-shadow: none; }
  .resumeDownloadButton { font-size: 14px; padding: 6px 12px; min-width: 150px; }
  .resumeDownloadButton p { margin: 0 6px; font-size: 14px; }
  .footer {
    min-height: 120px;
    padding: 15px 0;
  }
  .footer-heading {
    font-size: 14px;
    padding: 0 5px;
    margin-bottom: 15px;
  }
  .footer-links {
    height: auto;
    grid-gap: 10px;
    gap: 10px;
    padding: 0 5px;
  }
  .footer-links img {
    height: 25px;
    width: 25px;
  }
  .Logo { width: auto; } /* Updated for hamburger */
  .logoLeft, .logoRight { height: 40px; } /* Updated for hamburger */
}

@media screen and (max-width: 400px) {
  .semester-column { min-width: 160px; }
  .class-box { width: 100px; height: 35px; font-size: 0.9rem; }
  .classes-legend { grid-gap: 0.75rem; gap: 0.75rem; }
  .legend-item { min-width: 90px; }
  .video-grid-container { padding: 8px; }
  .video-item { flex: 0 1 100%; }
  .video-overlay h3 { font-size: 1rem; }
  .video-overlay p { font-size: 0.8rem; }
}

@media screen and (max-width: 351px) {
  .Navbar { padding: 5px 5px; } /* Updated for hamburger */
  .hamburger { margin-right: 5px; } /* Updated for hamburger */
  .App-header-vertical { right: 5px; width: 100px; top: 50px; padding: 8px; } /* Updated for hamburger */
  .App-link.vertical { font-size: 12px; padding: 6px 8px; } /* Updated for hamburger */
  .projectPage { margin: 1% 0; padding: 0 10px; width: 100%; }
  .projectPage > h2 { font-size: 24px; }
  .footer {
    min-height: 100px;
    padding: 10px 0;
  }
  .footer-heading {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .footer-links {
    grid-gap: 8px;
    gap: 8px;
  }
  .footer-links img {
    height: 20px;
    width: 20px;
  }
  .logoLeft, .logoRight { height: 35px; } /* Updated for hamburger */
}

@media screen and (max-width: 1200px) {
  .video-grid-container { max-width: 900px; }
}

/* Responsive Modal */
@media screen and (max-width: 500px) {
  .modal-content {
    padding: 1.5rem;
    width: 85%;
  }
  .modal-content h2 {
    font-size: 1.5rem;
  }
  .modal-content p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  .modal-button {
    padding: 8px 20px;
    font-size: 1rem;
  }
}

/* Additional Media Queries */
@media screen and (max-width: 900px) {
  .cubeContainer { margin: 10px auto; } /* Updated to match cube change */
  .heroContainer,
  .musicContainer {
    flex-direction: column;
    align-items: center;
    margin: 5% 0;
  }
  .heroText,
  .cubeContainer,
  .spotifyContainer,
  .latestInfo {
    margin: 15px 0; /* Updated: Vertical spacing for stacked layout */
    width: 100%;
    max-width: 500px; /* Updated: Cap width for smaller screens */
  }
  .spotifyContainer {
    max-width: 400px; /* Updated: Slightly smaller for better fit */
  }
  .latestInfo {
    max-width: 370px; /* Updated: Maintain original width cap */
  }
  .projectPage > h2 { font-size: 36px; }
  .resumePreview { max-width: 700px; }
}

@media screen and (max-width: 600px) {
  .video-grid-container { padding: 10px; }
  .video-item { flex: 0 1 100%; }
  .classes-container { margin: 1rem auto; padding: 0.5rem; }
  .semester-row { grid-gap: 0.5rem; gap: 0.5rem; }
  .footer-links { flex-direction: column; height: auto; padding: 10px; }
}

@media screen and (max-width: 450px) {
  .heroText > h1 { font-size: 40px; line-height: 48px; }
  .heroText > h3, .heroText > h4 { font-size: 18px; line-height: 24px; }
  .homeButtons { font-size: 14px; padding: 5px 8px; }
  .aboutText > h2 { font-size: 28px; }
  .aboutText > p { font-size: 14px; }
  .resumeContainer h2 { font-size: 20px; }
  .modal-content { padding: 1rem; }
  .modal-content h2 { font-size: 1.2rem; }
  .modal-content p { font-size: 0.8rem; }
}
